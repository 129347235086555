import arena_token from "assets/icons/v1/arena_token_knockout.svg";
import creator_circle_arenaclosed from "assets/icons/v1/creator/arenacircle_arenaclosed.svg";
import creator_circle_arenaisfull from "assets/icons/v1/creator/arenacircle_arenaisfull.svg";
import creator_circle_closed from "assets/icons/v1/creator/arenacircle_closed.svg";
import creator_circle_coming from "assets/icons/v1/creator/arenacircle_comingsoon.svg";
import creator_circle_create from "assets/icons/v1/creator/arenacircle_createarena.svg";
import creator_circle_enter from "assets/icons/v1/creator/arenacircle_enterarena.svg";
import creator_circle_preparing from "assets/icons/v1/creator/arenacircle_preparing.svg";
import creator_swirl from "assets/icons/v1/creator/geo-swirl.svg";
import illo_arenafull from "assets/icons/v1/creator/illo_arenafull.svg";
import creator_arrow from "assets/icons/v1/icon_df_arrow-right-long.svg";
import play_circle from "assets/icons/v1/icon_m_play_circle_outline.svg";
import creator_plus from "assets/icons/v1/studio/icon-u_plus.svg";
import creator_smile from "assets/icons/v1/studio/icon_m_face.svg";

export const creator = {
    creator_circle_create,
    creator_circle_enter,
    creator_circle_coming,
    creator_circle_arenaisfull,
    creator_circle_preparing,
    creator_circle_arenaclosed,
    creator_circle_closed,
    creator_swirl,
    creator_plus,
    creator_smile,
    creator_arrow,
    illo_arenafull,
    arena_token,
    play_circle
};
