import css from "./ProductPage.module.scss";
export const CloakNetPage = (props) => {
    return (
        <div className={css.product}>
            <h2>Secure, Closed Network Environment</h2>
            <p>
                <b>(Active development, upgraded release timeline, early 2026)</b>
            </p>
            <p>
                Cloak.net is an advanced solution for creating highly secure, closed network environments using the public Internet. It is specifically designed to provide impenetrable protection for node-to-node communications in corporate and government network environments. Leveraging
                cutting-edge encryption technologies and dynamic security protocols, Cloak.net ensures that sensitive data remains completely secure from unauthorized access or interference, even in the face of the most sophisticated cyber threats. By providing robust, scalable security with minimal
                overhead, Cloak.net enables organizations to protect their critical communications infrastructure while maintaining operational efficiency.
            </p>

            <h2>Core Features</h2>
            <ul>
                <li>
                    <strong>Node Security</strong>: Cloak.net ensures that each node within the network is secured against unauthorized access and prevents communication with unauthorized recipients, guaranteeing data integrity and confidentiality.
                </li>
                <li>
                    <strong>Comprehensive Communication Protection</strong>: Authorized nodes can communicate securely across all ports, ensuring privacy and secure data transmission over the public Internet, with no compromise on security.
                </li>
                <li>
                    <strong>Efficient Performance</strong>: Cloak.net provides high-level security with minimal resource overhead, allowing for seamless integration into existing network infrastructures without performance degradation.
                </li>
                <li>
                    <strong>Dynamic Encryption Mechanisms</strong>: Cloak.net dynamically changes encryption algorithms, keys, and key lengths as frequently as every packet, ensuring that no encryption scheme is repeated and making it virtually impossible for attackers to breach the system.
                </li>
                <li>
                    <strong>Stealth Mode Operation</strong>: Nodes remain undetectable during unauthorized scans, utilizing silent node technology to avoid detection by potential attackers, enhancing security and reducing the risk of intrusion.
                </li>
                <li>
                    <strong>Proactive Event Monitoring</strong>: Cloak.net features event-driven monitoring that can automatically trigger reports or responses when unauthorized activity is detected, enabling proactive security management.
                </li>
            </ul>

            <h2>Proven Security and Reliability</h2>
            <p>
                Cloak.net has been rigorously tested against a wide range of cyberattacks, including attempts by top security professionals and government agencies. Its unyielding security has withstood penetration tests from industry-leading tools and manual techniques, demonstrating its capacity
                to secure sensitive communications and network infrastructures. Cloak.net has been deployed across corporate and government sectors, providing unparalleled security in environments that require the highest level of protection.
            </p>
        </div>
    );
};
