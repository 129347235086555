import at_sign from "assets/icons/v1/form/icon_m_alternate-email.svg";
import checkbox_checked from "assets/icons/v1/form/icon_m_check_box.svg";
import checkbox_blank from "assets/icons/v1/form/icon_m_check_box_outline_blank.svg";
import download from "assets/icons/v1/form/icon_m_download.svg";
import circle_error from "assets/icons/v1/form/icon_m_error.svg";
import key from "assets/icons/v1/form/icon_m_key.svg";
import link from "assets/icons/v1/form/icon_m_link.svg";
import show_password from "assets/icons/v1/form/icon_m_visibility.svg";
import hide_password from "assets/icons/v1/form/icon_m_visibility_off.svg";
import circle_check from "assets/icons/v1/form/icon_u_check-circle-outline.svg";
import circle_check_fill from "assets/icons/v1/icon_m_check_circle.svg";

export const form = {
    checkbox_checked,
    checkbox_blank,
    circle_check,
    circle_check_fill,
    circle_error,
    at_sign,
    show_password,
    hide_password,
    link,
    key,
    download
};
