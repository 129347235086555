import UXIcon from "../../ux/icon";
import css from "./NavSlider.module.scss";
import {products} from "./content";

export const NavSlider = (props) => {
    const {showSlider, pageKey, bodyClasses, onClickTogglePage} = props;

    const productList = _.keys(products);
    const showingPage = pageKey !== null;

    return (
        <div className={classnames(css.nav_slider, {[css.open]: showSlider}, bodyClasses(props))}>
            <div className={css.nav_box}>
                {productList.map((product, i) => {
                    const selected = pageKey == product;
                    const icon = selected ? css.spin : "";
                    return (
                        <div key={product} className={classnames({[css.selected]: selected})} onClick={onClickTogglePage(product)}>
                            {product}
                            <span>
                                <UXIcon name="arcaneus_add" classes={icon} />
                            </span>
                        </div>
                    );
                })}
                <div className={classnames({[css.selected]: pageKey == "About"})} onClick={onClickTogglePage("About")}>
                    About
                </div>
            </div>
        </div>
    );
};
