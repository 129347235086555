import coins_hand from "assets/icons/v2/finance/icon_un_coins_hand_solid.svg";
import finances from "assets/icons/v2/finance/icon_un_currency_dollar_circle.svg";
import diamond from "assets/icons/v2/finance/icon_un_diamond_01.svg";
import cart_fill from "assets/icons/v2/finance/icon_un_shopping_cart_01_fill.svg";
import cart_line from "assets/icons/v2/finance/icon_un_shopping_cart_01_line.svg";
import wallet from "assets/icons/v2/finance/icon_un_wallet_02.svg";

export const finance = {
    wallet,
    coins_hand,
    diamond,
    cart_fill,
    cart_line,
    finances
};
