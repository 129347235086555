import {motion} from "framer-motion";
import {DateTime} from "luxon";
import React, {useEffect, useState} from "react";
import css from "./Quotes.module.scss";
import {quotes} from "./content";
export const Quotes = (props) => {
    const {mobile} = props;
    const now = DateTime.now();
    const currentDay = now.day;
    const startingQuoteIndex = currentDay % quotes.length;
    const [quoteIndex, setQuoteIndex] = useState(startingQuoteIndex);

    useEffect(() => {
        setTimeout(() => {
            setQuoteIndex((quoteIndex + 1) % quotes.length);
        }, 10000);
    }, [quoteIndex]);

    const currentQuote = quotes[quoteIndex];
    const [quoteCopy, quoteCredits] = currentQuote;
    const copyText = quoteCopy ? '"' + quoteCopy + '"' : "";
    const creditsText = quoteCredits ? "- " + quoteCredits : "";

    return (
        <div className={classnames(css.quote, {[css.mobile]: mobile})}>
            <div className={css.copy}>
                <DrawText copy={copyText} index={quoteIndex} />
            </div>
            <div key={quoteIndex} className={css.credits}>
                {creditsText}
            </div>
        </div>
    );
};

const DrawText = (props) => {
    const {copy, index} = props;
    const text = copy.split(" ");

    return (
        <div key={index} className={css.copy}>
            {text.map((el, i) => (
                <motion.span
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{
                        duration: 0.5,
                        delay: i / 10
                    }}
                    key={i}>
                    {el}{" "}
                </motion.span>
            ))}
        </div>
    );
};
