import {AboutPage} from "./pages/AboutPage";
import {CloakNetPage} from "./products/CloakNetPage";
import {EnigmaPage} from "./products/EnigmaPage";
import {GuardianPage} from "./products/GuardianPage";
import {JavelinPage} from "./products/JavelinPage";
import {SentinelPage} from "./products/SentinelPage";
import {VelocityPage} from "./products/VelocityPage";

const quotes = [
    ["Quantum computing will redefine the landscape of cryptography, and we must prepare by adopting quantum-resistant algorithms to safeguard national security.", "General Paul Nakasone, Director of the NSA and Commander of U.S. Cyber Command"],
    [
        "The advent of quantum computing means our encryption systems face unprecedented threats. Governments and industries alike must prioritize the development and deployment of quantum-proof encryption technologies.",
        "Ciaran Martin, Former Chief Executive of the UK National Cyber Security Centre (NCSC)"
    ],
    ["Quantum technology is coming at us faster than we expected. The potential for quantum computers to break current encryption systems could expose critical national infrastructure to unprecedented vulnerabilities.", "Jeremy Fleming, Director of GCHQ"],
    [
        "Quantum computing is a game changer for cybersecurity. Its power can defeat encryption at scales we haven’t encountered before, demanding a coordinated global response to secure military and civilian data.",
        "Lt. Gen. Vincent Stewart, Former Director of the U.S. Defense Intelligence Agency (DIA)"
    ],
    ["As quantum technologies advance, they will pose a direct threat to the cybersecurity infrastructure that protects the UK’s critical defense networks. We must act now to fortify our encryption capabilities.", "General Sir Patrick Sanders, Chief of the General Staff, British Army"],
    ["Quantum computing could render traditional encryption obsolete, exposing sensitive defense and intelligence data. Our defense sector must stay ahead by investing in quantum-resistant security measures.", "Michael Rogers, Former Director of the NSA and U.S. Cyber Command"],
    ["Quantum computing poses one of the greatest future challenges for intelligence agencies. We must invest heavily in quantum-safe encryption to protect our most sensitive operations.", "Sir Alex Younger, Former Chief of MI6"],
    ["Quantum computers will break most of the cryptography we currently use, forcing the world to adopt quantum-resistant algorithms. This transition will be one of the biggest challenges in cybersecurity.", "Bruce Schneier, Security Technologist"],
    ["Quantum computing is one of the greatest threats to the current encryption methods that protect everything from banking to healthcare. We need quantum-resistant encryption now to future-proof these systems.", "Harri Hursti, Cybersecurity Expert"],
    ["The rise of quantum computing necessitates a rethinking of cryptographic systems. Classical systems like RSA will become obsolete, and new quantum-safe alternatives must take their place.", "Rivest, Shamir, and Adleman, Inventors of RSA Encryption"],
    ["The risk that quantum computers will break today’s cryptographic infrastructure is real and growing. We need to move to post-quantum cryptography, not in the distant future, but now.", "Michele Mosca, Co-Founder of the Institute for Quantum Computing"],
    ["Quantum computing poses a real risk to today's encryption standards. We’re actively working on new cryptographic techniques to ensure we’re ready when this technology matures.", "Heather Adkins, Director of Information Security, Google"],
    ["The shift to quantum computing represents a tectonic shift in cybersecurity. Encryption schemes that are quantum-safe must be developed and deployed well before quantum computers are fully realized.", "Whitfield Diffie, Co-Inventor of Public Key Cryptography"],
    ["Quantum computers and AI are bringing the next era of cybersecurity threats. We have to anticipate those risks by investing in encryption that is resilient to both.", "Lila Tretikov, COO of Microsoft"],
    ["Encryption will need to evolve in response to the advancements in quantum technology. The need to secure data at scale has never been more critical.", "Craig Federighi, Senior Vice President of Software Engineering, Apple"],
    ["Quantum computers will undermine the encryption techniques on which our digital privacy rests. New protocols must be devised to maintain security in a quantum world.", "David Chaum, Father of Online Privacy"],
    ["The day is coming when quantum computers will render much of today’s encryption obsolete. We must be ready with quantum-resistant cryptography to protect future communications.", "Phil Zimmermann, Creator of PGP Encryption"],
    ["AI and quantum computing are rewriting the rules of cybersecurity. We need encryption methods that can stand up to the brute force of quantum processing.", "Sue Gordon, Former Principal Deputy Director of National Intelligence"],
    ["Quantum computers will radically change our approach to cybersecurity. Encrypted data will no longer be safe without the use of quantum-resistant cryptographic algorithms.", "Arvind Krishna, CEO of IBM"],
    ["Quantum computers are coming, and when they do, they will destroy the encryption that secures our digital world. The race is on to develop quantum-safe encryption before it’s too late.", "Andy Greenberg, Cybersecurity Journalist"],
    ["The emergence of quantum computing and AI is reshaping the security landscape. We must adapt by implementing cryptographic defenses that are quantum-resistant.", "Satya Nadella, CEO of Microsoft"],
    ["We must stay ahead of the quantum and AI revolution by integrating encryption that can protect critical infrastructures against their unprecedented computational powers.", "Dana Deasy, Former CIO of the U.S. Department of Defense"],
    ["Quantum computing will eventually break traditional encryption schemes, and we need to develop and deploy quantum-safe algorithms sooner rather than later.", "Charles Giancarlo, Former CTO of Cisco Systems"],
    ["Quantum computers represent a threat that cannot be overstated. Encryption technologies must evolve rapidly to keep pace with the threat posed by this new computing paradigm.", "Michael Brown, CEO of Symantec"],
    ["The advent of quantum computing will compromise most of our encryption protocols. The challenge is to design encryption that can withstand both classical and quantum attacks.", "Vincent Rijmen, Co-Inventor of AES Encryption"],
    ["In the face of rapidly advancing AI and quantum technologies, the only path forward is a shift towards cryptographic agility. We must prepare for a quantum-resistant future to ensure our users' data remains secure.", "Mark Risher, Head of Security & Privacy, Google"]
];

const products = {Javelin: JavelinPage, Guardian: GuardianPage, Enigma: EnigmaPage, Velocity: VelocityPage, Sentinel: SentinelPage, "Cloak.net": CloakNetPage};
const pages = {About: AboutPage};

export {pages, products, quotes};
