import flash from "assets/icons/v2/media/icon_un_flash.svg";
import play_square from "assets/icons/v2/media/icon_un_play_square.svg";
import signal from "assets/icons/v2/media/icon_un_signal_03.svg";
import tv from "assets/icons/v2/media/icon_un_tv_03.svg";
import video_fill from "assets/icons/v2/media/icon_un_video_recorder_fill.svg";
import video_line from "assets/icons/v2/media/icon_un_video_recorder_line.svg";

export const media = {
    tv,
    flash,
    play_square,
    video_fill,
    video_line,
    signal
};
