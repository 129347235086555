import button_follow_show from "assets/icons/v1/button-follow-show.svg";
import button_join_the_show from "assets/icons/v1/button-join-the-show.svg";
import button_share_show from "assets/icons/v1/button-share-show.svg";
import button_watchlist_save from "assets/icons/v1/button-watchlist-save.svg";
import button_spinner from "assets/icons/v1/icon-u_redo.svg";
import stream from "assets/icons/v1/icon_m_stream.svg";
import button_mystreams from "assets/icons/v1/icon_m_video_call.svg";
import wave_left from "assets/icons/v1/icon_m_wavinghand_left.svg";
import wave_right from "assets/icons/v1/icon_m_wavinghand_right.svg";

export const buttons = {
    button_join_the_show,
    button_watchlist_save,
    button_follow_show,
    button_share_show,
    button_mystreams,
    button_spinner,
    stream,
    wave_left,
    wave_right
};
