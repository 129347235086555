import {AnimatePresence, motion} from "framer-motion";
import React from "react";
import UXIcon from "../../../ux/icon";
import css from "./ProductPage.module.scss";
// animates individual product views in and out of home page
export const ProductPage = (props) => {
    const {pageKey, onClickTogglePage, allPages, mobile} = props;

    return (
        <AnimatePresence>
            {pageKey && (
                <motion.div className={classnames(css.view, {[css.mobile]: mobile})} initial={{top: "100%"}} animate={{top: "0%"}} exit={{top: "100%"}} transition={{duration: 0.3}}>
                    <ProductView key={pageKey} name={pageKey} onClickExit={onClickTogglePage} allPages={allPages} />
                </motion.div>
            )}
        </AnimatePresence>
    );
};

// design of the interior product view page
const ProductView = (props) => {
    const {name, allPages, onClickExit} = props;
    const productPage = allPages[name] || null;
    const componentPage = productPage ? React.createElement(productPage, props) : null;

    return (
        <section className={css.section}>
            <div className={css.sectionHeader}>
                <div>{name}</div>
                <div onClick={onClickExit(null)}>
                    <UXIcon name="add" />
                </div>
            </div>
            {componentPage}
        </section>
    );
};
