import css from "./ProductPage.module.scss";

export const EnigmaPage = (props) => {
    return (
        <div className={css.product}>
            <h2>TCP-Based Accelerated Transport Tunnel</h2>
            <p>
                Enigma is an advanced transport tunnel that leverages a parallel connection architecture for secure and efficient communications between servers. Similar to a SOCKS 5 Proxy server, Enigma routes data through multiple network pathways in parallel, ensuring high-performance and
                quantum-proof encryption for secure data transfer. By breaking data into micro-blocks and securing each with 4096-bit encryption, Enigma is designed to meet the highest security standards for mission-critical communications.
            </p>

            <h2>Core Features</h2>
            <ul>
                <li>
                    <strong>TCP-based Secure Tunnel</strong>: Enigma establishes a secure tunnel for communications, utilizing a TCP-based architecture for reliable data transfer.
                </li>
                <li>
                    <strong>Quantum-Proof Encryption</strong>: With 4096-bit in-flight encryption, Enigma protects all data transferred, ensuring that communications remain secure against future quantum computing threats.
                </li>
                <li>
                    <strong>Proprietary Parallel TCP Protocol</strong>: Enigma’s unique parallel TCP protocol allows data to be sent across multiple network pathways simultaneously, enhancing both performance and security.
                </li>
                <li>
                    <strong>Multiple Network Pathways</strong>: Enigma's data transport traverses multiple network paths, creating obfuscation and reducing the likelihood of interception, while enhancing network binding.
                </li>
                <li>
                    <strong>TCP and HTTPS Ingress/Egress Connections</strong>: Enigma supports both TCP and HTTPS connections for secure data input and output.
                </li>
                <li>
                    <strong>High Performance</strong>: Optimized for high-speed, low-latency communication, Enigma ensures efficient transport of data with minimal delays.
                </li>
                <li>
                    <strong>Flexible Deployment Options</strong>: Enigma is deployable as a small binary on bare-metal servers, Docker containers, or hardware appliances, providing flexibility in deployment environments.
                </li>
                <li>
                    <strong>Front-Side Load Balancing</strong>: Enigma supports front-side load balancing, ensuring that network traffic is efficiently distributed to maintain performance.
                </li>
            </ul>
        </div>
    );
};
