import events_fill from "assets/icons/v2/nav/icon_un_calendar_fill.svg";
import events_line from "assets/icons/v2/nav/icon_un_calendar_line.svg";

import gaming_fill from "assets/icons/v2/nav/icon_un_gaming_pad_01_fill.svg";
import gaming_line from "assets/icons/v2/nav/icon_un_gaming_pad_01_line.svg";

import discover_fill from "assets/icons/v2/nav/icon_un_search_md_fill.svg";
import discover_line from "assets/icons/v2/nav/icon_un_search_md_line.svg";

import home_fill from "assets/icons/v2/nav/icon_un_home_05_fill.svg";
import home_line from "assets/icons/v2/nav/icon_un_home_05_line.svg";

import podcasts_fill from "assets/icons/v2/nav/icon_un_microphone_01_fill.svg";
import podcasts_line from "assets/icons/v2/nav/icon_un_microphone_01_line.svg";

import music_fill from "assets/icons/v2/nav/icon_un_music_note_01_fill.svg";
import music_line from "assets/icons/v2/nav/icon_un_music_note_01_line.svg";

import shopping_fill from "assets/icons/v2/nav/icon_un_shopping_cart_01_fill.svg";
import shopping_line from "assets/icons/v2/nav/icon_un_shopping_cart_01_line.svg";

export const nav = {
    events_fill,
    events_line,
    gaming_fill,
    gaming_line,
    podcasts_fill,
    podcasts_line,
    discover_line,
    discover_fill,
    home_line,
    home_fill,
    music_line,
    music_fill,
    shopping_line,
    shopping_fill
};
