import {avatars} from "./avatars";
import {badges} from "./badges";
import {buttons} from "./buttons";
import {creator} from "./creator";
import {editor} from "./editor";
import {events} from "./events";
import {form} from "./form";
import {media} from "./media";
import {misc} from "./misc";
import {nav} from "./nav";
import {overlay} from "./overlay";
import {payments} from "./payments";
import {player} from "./player";
import {reactions} from "./reactions";
import {settings} from "./settings";
import {social} from "./social";
import {studio} from "./studio";
import {themes} from "./themes";

const iconset = {
    ...misc,
    ...overlay,
    ...buttons,
    ...nav,
    ...reactions,
    ...player,
    ...studio,
    ...avatars,
    ...media,
    ...creator,
    ...badges,
    ...settings,
    ...editor,
    ...form,
    ...social,
    ...themes,
    ...events,
    ...payments
};

export default iconset;
