import create_arena from "assets/icons/v2/editor/icon_un_bezier_curve_03.svg";
import palette_un from "assets/icons/v2/editor/icon_un_palette.svg";
import pencil from "assets/icons/v2/editor/icon_un_pencil_02_fill.svg";
import perspective_line from "assets/icons/v2/editor/icon_un_perspective_02.svg";
import perspective_fill from "assets/icons/v2/editor/icon_un_perspective_02_fill.svg";

export const editor = {
    create_arena,
    pencil,
    palette_un,
    perspective_line,
    perspective_fill
};
