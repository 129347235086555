import css from "../products/ProductPage.module.scss";

export const AboutPage = (props) => {
    return (
        <div className={css.product}>
            <h2>Our Team</h2>
            <p>
                At Arcaneus, we believe that building exceptional solutions requires a team with unparalleled expertise and dedication. Our team consists of highly skilled professionals with decades of experience in fields such as computer architecture, software and hardware design, cryptography,
                cybersecurity, and advanced systems engineering. These professionals have contributed to groundbreaking technologies, driving innovation across various industries including telecommunications, government, and defense sectors.
            </p>
            <p>
                With experience spanning startups to large public companies, our team brings a deep understanding of the challenges faced by modern organizations. Their combined expertise has led to the development of proprietary quantum-proof encryption technologies, real-time communication
                systems, secure data storage solutions, and advanced network security protocols. Our team members have authored patents, architected scalable systems, and designed high-performance solutions for mission-critical applications.
            </p>
            <p>
                Throughout their careers, members of the Arcaneus team have had the privilege of working with a wide range of industry-leading organizations, including <strong>Advanced Micro Devices (AMD)</strong>, <strong>Alcatel-Lucent</strong>, <strong>American Bankers Association</strong>,{" "}
                <strong>Bank of America</strong>, <strong>Bosch</strong>, <strong>Cooper Tires</strong>, <strong>Ford Motor Company</strong>, <strong>Georgia Power</strong>, <strong>Goodyear</strong>, <strong>H&R Block</strong>, <strong>IBM</strong>, <strong>Intel Corporation</strong>,{" "}
                <strong>Jet Propulsion Laboratory (JPL)</strong>, <strong>TIBCO</strong>, <strong>Merrill Lynch</strong>, <strong>Michelin</strong>, <strong>Motorola Semiconductor</strong>, <strong>Nielsen</strong>, <strong>Northrop Grumman</strong>, <strong>Philips Semiconductors</strong>,{" "}
                <strong>Pirelli</strong>, <strong>Sheridan Publishing</strong>, <strong>Standard & Poor&apos;s</strong>, <strong>SunTrust</strong>, <strong>Sungard</strong>, <strong>Synovus</strong>, <strong>Telular</strong>, <strong>Total Systems</strong>, <strong>Turner Broadcasting</strong>, and{" "}
                <strong>Xerox</strong>. This diverse experience enables us to tackle complex challenges and deliver cutting-edge solutions across multiple sectors.
            </p>
            <p>
                Our engineers and technologists are adept at optimizing systems for performance and security, and delivering innovative products that protect data and communications in the most demanding environments. Their work has been validated through rigorous testing, with security solutions
                that have proven resilient even under scrutiny by top security experts and government agencies.
            </p>
            <p>
                At Arcaneus, our team is driven by a shared passion for innovation, problem-solving, and creating technologies that redefine the boundaries of what’s possible in secure communications and data protection. Together, we are committed to delivering the highest standards of excellence
                and security in everything we build.
            </p>

            <h2>ARCANE (Algorithmic Resilient Cryptography and Network Encryption)</h2>
            <p>
                ARCANE is a cutting-edge encryption technology designed to protect data from the most advanced attacks, including those from quantum computing. Leveraging the unique Anti-Statistical Block Encryption (ASBE) algorithm, ARCANE offers non-deterministic encryption, unmatched in its
                ability to secure data in real-time, mission-critical environments.
            </p>

            <h2>Key Differentiators</h2>
            <ul>
                <li>
                    <strong>Dynamic and Non-Deterministic Encryption</strong>: ARCANE’s encryption algorithm ensures that no two encryption instances are alike, producing different ciphertext for every encryption, even when using the same key, input, and digital signature. This stochastic process
                    defends against statistical analysis, AI, and quantum computing attacks.
                </li>
                <li>
                    <strong>Mathematics-Free Architecture</strong>: ARCANE’s ASBE algorithm avoids the use of traditional mathematical formulas like AES or RSA, making it immune to mathematical analysis and quantum computing decryption methods. This provides unmatched resilience to future threats.
                </li>
                <li>
                    <strong>Keyless Security Architecture</strong>: With ARCANE, encryption keys are created, used, and destroyed during each encryption and decryption instance, eliminating the need for key exchange, storage, or transfer. This model drastically reduces vulnerabilities and common
                    attack vectors.
                </li>
                <li>
                    <strong>Variable Key Sizes and Strength</strong>: ARCANE’s dynamic key sizes range from 2008 bits to 2 GB, vastly enhancing security. This flexibility makes it exponentially stronger than conventional encryption keys, ensuring protection against even the most powerful attacks.
                </li>
                <li>
                    <strong>Quantum-Proof Resilience</strong>: ARCANE’s non-deterministic encryption process is designed to be immune to the advanced decryption techniques used by quantum computers, ensuring long-term data security.
                </li>
                <li>
                    <strong>Advanced Authentication Systems</strong>: ARCANE incorporates five layers of parameterized authentication systems to establish dynamic trust between machines, applications, and people, providing enhanced security without the need for external certificate authorities.
                </li>
                <li>
                    <strong>Random Data Generator (RDG)</strong>: ARCANE’s built-in RDG never repeats a pattern, preventing hackers from exploiting periodic behaviors in the encryption process. This ensures protection against traditional and quantum computing-based attacks by introducing
                    unpredictable randomness.
                </li>
            </ul>

            <h2>Contact Arcaneus</h2>
            <p>Arcaneus has locations in the United States and United Kingdom, with headquarters in Atlanta, Georgia.</p>
            <p>
                For inquiries, please reach us at{" "}
                <a href="mailto:sales@arcaneus">
                    <strong>sales@arcaneus.com</strong>
                </a>
                .
            </p>
        </div>
    );
};
