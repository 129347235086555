import user_kick from "assets/icons/v2/user/icon_m_sports_martial_arts.svg";
import user_default from "assets/icons/v2/user/icon_un_user_03.svg";
import user_circle from "assets/icons/v2/user/icon_un_user_circle.svg";
import user_plus from "assets/icons/v2/user/icon_un_user_plus_01_solid.svg";
import users from "assets/icons/v2/user/icon_un_users_01.svg";

export const user = {
    user_default,
    user_circle,
    user_plus,
    user_kick,
    users
};
