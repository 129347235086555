import css from "./ProductPage.module.scss";

export const SentinelPage = (props) => {
    return (
        <div className={css.product}>
            <h2>Fiber Optic Perimeter Threat Detection System</h2>
            <p>
                <b>(Partnered solution)</b>
            </p>

            <p>
                Sentinel is a state-of-the-art fiber optic perimeter threat detection system that leverages Distributed Acoustic Sensing (DAS) technology to deliver superior, multi-threat detection capabilities. Unlike conventional sensors, Sentinel reduces false alarms, provides precise
                classification of multiple activities, and offers seamless, cost-effective installation. Sentinel is the ideal solution for high-security environments, offering unmatched reliability and flexibility in protecting critical assets.
            </p>

            <h2>Core Features</h2>
            <ul>
                <li>
                    <strong>Multi-Threat Detection</strong>: Sentinel can detect, classify, and locate various activities, including personnel, vehicle movement, low-flying aircraft, digging, and gunfire, providing comprehensive perimeter security.
                </li>
                <li>
                    <strong>Reliable Detection Alarms</strong>: Using advanced algorithms, Sentinel minimizes nuisance alarms, ensuring accurate detection of real threats in real time. Notifications can be sent via text or email, and GPS coordinates enable precise location identification within
                    seconds.
                </li>
                <li>
                    <strong>Seamless Installation</strong>: Sentinel supports fence-mounted, buried, or hybrid installations, providing design flexibility for various environments. No certification is required, and all electronics are installed indoors for protection from EMI, RFI, and environmental
                    conditions.
                </li>
                <li>
                    <strong>Scalable and Expandable</strong>: Sentinel is a software-based system, making it easily adjustable to perimeter changes and allowing for integration with other security systems, such as CCTV and VMSS.
                </li>
                <li>
                    <strong>Passive and Undetectable Protection</strong>: Sentinel operates using standard fiber optic telecom cables, providing continuous coverage without the need for in-field power or maintenance.
                </li>
                <li>
                    <strong>Exceptional Wind Noise Rejection</strong>: Designed to handle environmental noise, Sentinel delivers reliable performance in a range of challenging conditions.
                </li>
                <li>
                    <strong>Reduced False Alarms</strong>: By utilizing advanced signal processing, Sentinel drastically reduces the incidence of false alarms, ensuring only legitimate threats are detected.
                </li>
            </ul>

            <h2>Applications</h2>
            <ul>
                <li>Borders and military installations</li>
                <li>Utility protection (water, energy, communications)</li>
                <li>Industrial facilities (chemical, petrochemical, refineries)</li>
                <li>Transportation hubs (roads, rail, ports, airports)</li>
                <li>Commercial properties (data centers, distribution facilities)</li>
                <li>VIP residences, golf courses, resorts</li>
            </ul>
        </div>
    );
};
