import {alerts} from "./alerts";
import {arrows} from "./arrows";
import {backgrounds} from "./backgrounds";
import {badge} from "./badge";
import {button} from "./button";
import {charts} from "./charts";
import {communication} from "./communication";
import {development} from "./development";
import {editor} from "./editor";
import {education} from "./education";
import {fifth} from "./fifth";
import {finance} from "./finance";
import {general} from "./general";
import {layout} from "./layout";
import {logo} from "./logo";
import {maps} from "./maps";
import {media} from "./media";
import {meta} from "./meta";
import {more} from "./more";
import {nav} from "./nav";
import {nav_admin} from "./nav_admin";
import {security} from "./security";
import {shapes} from "./shapes";
import {social} from "./social";
import {time} from "./time";
import {user} from "./user";

const iconset = {
    ...arrows,
    ...badge,
    ...button,
    ...logo,
    ...user,
    ...nav,
    ...nav_admin,
    ...more,
    ...time,
    ...social,
    ...meta,
    ...general,
    ...editor,
    ...media,
    ...layout,
    ...finance,
    ...alerts,
    ...shapes,
    ...communication,
    ...maps,
    ...education,
    ...security,
    ...charts,
    ...backgrounds,
    ...development,
    ...fifth
};

export default iconset;
