import arena_token_tm from "assets/icons/v1/arena_token_knockout_tm.svg";
import arena_token_white from "assets/icons/v1/avatars/arena-token-onwhite.svg";
import circle from "assets/icons/v1/circle.svg";
import discover_arcs from "assets/icons/v1/discover-arcs.svg";
import arrow_right from "assets/icons/v1/form/icon_m_arrow_forward.svg";
import greenroom_tile from "assets/icons/v1/greenroom/roomtile_greenroom.svg";
import lightbulb from "assets/icons/v1/help/icon_m_lightbulb.svg";
import network_check from "assets/icons/v1/icon-m-network-check.svg";
import arena_a from "assets/icons/v1/icon_df_arena_a.svg";
import circle_dotted from "assets/icons/v1/icon_df_dottedcircle.svg";
import green_room_flower from "assets/icons/v1/icon_df_greenroomflower.svg";
import recent from "assets/icons/v1/icon_m_access_time.svg";
import arrow_left from "assets/icons/v1/icon_m_arrow_back.svg";
import arrow_outward from "assets/icons/v1/icon_m_arrow_outward.svg";
import subscribe_on from "assets/icons/v1/icon_m_bookmark.svg";
import subscribe from "assets/icons/v1/icon_m_bookmark_border.svg";
import chevron_left from "assets/icons/v1/icon_m_chevron_left.svg";
import chevron_right from "assets/icons/v1/icon_m_chevron_right.svg";
import close from "assets/icons/v1/icon_m_close.svg";
import copy from "assets/icons/v1/icon_m_content_copy.svg";
import donot_disturb from "assets/icons/v1/icon_m_do_disturb_alt.svg";
import edit from "assets/icons/v1/icon_m_edit.svg";
import event from "assets/icons/v1/icon_m_event.svg";
import follow_on from "assets/icons/v1/icon_m_favorite.svg";
import follow from "assets/icons/v1/icon_m_favorite_border.svg";
import tilt from "assets/icons/v1/icon_m_filter_tilt_shift.svg";
import gamepad from "assets/icons/v1/icon_m_gamepad.svg";
import history from "assets/icons/v1/icon_m_history.svg";
import hive from "assets/icons/v1/icon_m_hive.svg";
import information from "assets/icons/v1/icon_m_info_outline.svg";
import menu from "assets/icons/v1/icon_m_menu.svg";
import packet_loss from "assets/icons/v1/icon_m_mobiledata_off.svg";
import donate_on from "assets/icons/v1/icon_m_monetization_fill.svg";
import navigation from "assets/icons/v1/icon_m_navigation.svg";
import palette from "assets/icons/v1/icon_m_palette.svg";
import person from "assets/icons/v1/icon_m_person.svg";
import status_icon_off from "assets/icons/v1/icon_m_sensorsoff.svg";
import status_icon_on from "assets/icons/v1/icon_m_sensorson.svg";
import share from "assets/icons/v1/icon_m_share.svg";
import signal from "assets/icons/v1/icon_m_signal_cellular_alt.svg";
import warning from "assets/icons/v1/icon_m_warning.svg";
import illo_eyetv from "assets/icons/v1/illo_eyetv.svg";
import arena_logo from "assets/icons/v1/logo-arenalogo.svg";
import quote from "assets/icons/v1/quote-symbol.svg";
import rectangle from "assets/icons/v1/rectangle.svg";
import backstage from "assets/icons/v1/studio/icon_m_swap_horizontal_circle.svg";
import ellipsis_h from "assets/icons/v1/u-ellipsis-h.svg";
import ellipsis_v from "assets/icons/v1/u-ellipsis-v.svg";

export const misc = {
    arrow_outward,
    arena_logo,
    ellipsis_h,
    ellipsis_v,
    event,
    subscribe,
    subscribe_on,
    follow,
    follow_on,
    share,
    backstage,
    navigation,
    palette,
    status_icon_on,
    status_icon_off,
    person,
    quote,
    chevron_left,
    chevron_right,
    arena_token_tm,
    arena_a,
    edit,
    close,
    circle,
    circle_dotted,
    rectangle,
    copy,
    network_check,
    illo_eyetv,
    signal,
    green_room_flower,
    greenroom_tile,
    lightbulb,
    packet_loss,
    donot_disturb,
    tilt,
    history,
    menu,
    recent,
    hive,
    gamepad,
    arrow_right,
    arrow_left,
    donate_on,
    arena_token_white,
    discover_arcs,
    warning,
    information
};
