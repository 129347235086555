import message_alert from "assets/icons/v2/communication/icon_un_message_alert_circle_fill.svg";
import message_check from "assets/icons/v2/communication/icon_un_message_check_square.svg";
import message_fill from "assets/icons/v2/communication/icon_un_message_square_02_fill.svg";
import message_line from "assets/icons/v2/communication/icon_un_message_square_02_line.svg";
import message_x from "assets/icons/v2/communication/icon_un_message_x_square.svg";
import message_pause from "assets/icons/v2/communication/icon_un_pause_square.svg";
import paper_airplane from "assets/icons/v2/communication/icon_un_send_01_fill.svg";

export const communication = {
    message_fill,
    message_line,
    message_alert,
    message_x,
    message_pause,
    message_check,
    paper_airplane
};
