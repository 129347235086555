import tv_retro from "assets/icons/v1/nav/icon_df_tv_retro.svg";
import grid_off from "assets/icons/v1/nav/icon_m_deselect.svg";
import explore from "assets/icons/v1/nav/icon_m_explore.svg";
import grid_on from "assets/icons/v1/nav/icon_m_library_add_check.svg";
import publish from "assets/icons/v1/nav/icon_m_publish.svg";
import search from "assets/icons/v1/nav/icon_m_search.svg";
import heart_sign from "assets/icons/v1/nav/u-heart-sign.svg";
import lamp from "assets/icons/v1/nav/u-lamp.svg";
import nav_search from "assets/icons/v1/nav/u-search.svg";
import shopping_bag from "assets/icons/v1/nav/u-shopping-bag.svg";
import nav_sliders from "assets/icons/v1/nav/u-sliders-v.svg";
import chat_settings from "assets/icons/v1/player/icon_m_settings.svg";

export const nav = {
    heart_sign,
    lamp,
    shopping_bag,
    tv_retro,
    nav_search,
    nav_sliders,
    grid_on,
    grid_off,
    search,
    explore,
    publish,
    chat_settings
};
