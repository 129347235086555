import studio_pilot from "assets/icons/v1/editor/icon_m_auto_awesome.svg";
import studio_chat from "assets/icons/v1/overlays/icon_m_chatbubble.svg";
import studio_flag from "assets/icons/v1/player/flag.svg";
import studio_rights_director from "assets/icons/v1/player/icon_m_movie_filter.svg";
import studio_rights_owner from "assets/icons/v1/player/icon_m_navigation.svg";
import studio_pause from "assets/icons/v1/player/icon_m_pause.svg";
import studio_play from "assets/icons/v1/player/icon_m_play_arrow.svg";
import studio_volume_mute from "assets/icons/v1/player/icon_m_volume_off.svg";
import studio_volume from "assets/icons/v1/player/icon_m_volume_up.svg";
import studio_star from "assets/icons/v1/player/star.svg";
import studio_publish from "assets/icons/v1/player/u-forward.svg";
import studio_record from "assets/icons/v1/player/u-record-audio.svg";
import studio_layouts from "assets/icons/v1/player/u-th-large.svg";
import studio_curtain from "assets/icons/v1/player/u-window-section.svg";
import studio_cast from "assets/icons/v1/studio/cast.svg";
import studio_my from "assets/icons/v1/studio/icon_m_face.svg";
import globe from "assets/icons/v1/studio/icon_m_language.svg";
import studio_live from "assets/icons/v1/studio/icon_m_live_tv.svg";
import studio_stage from "assets/icons/v1/studio/icon_m_movie_filter.svg";
import studio_palette from "assets/icons/v1/studio/icon_m_palette.svg";
import person from "assets/icons/v1/studio/icon_m_person.svg";
import studio_media from "assets/icons/v1/studio/icon_m_smart_display.svg";
import studio_carousel from "assets/icons/v1/studio/icon_m_view_carousel.svg";
import preview_grid from "assets/icons/v1/studio/transparency-grid.svg";

export const studio = {
    studio_cast,
    studio_publish,
    studio_curtain,
    studio_layouts,
    studio_record,
    studio_pause,
    studio_play,
    studio_volume,
    studio_volume_mute,
    studio_stage,
    studio_chat,
    studio_my,
    studio_star,
    studio_flag,
    studio_media,
    preview_grid,
    studio_rights_director,
    studio_rights_owner,
    studio_role_host: studio_star,
    studio_role_fan: studio_flag,
    studio_role_media: studio_media,
    studio_pilot,
    studio_palette,
    studio_live,
    studio_carousel,
    person,
    globe
};
