import donate from "assets/icons/v1/payments/icon_df_donate.svg";
import diamond from "assets/icons/v1/payments/icon_m_diamond.svg";
import checkout from "assets/icons/v1/payments/icon_m_shopping_bag.svg";
import success_check from "assets/icons/v1/payments/success_check_static.svg";

export const payments = {
    donate,
    checkout,
    diamond,
    success_check
};
