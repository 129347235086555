import css from "./ProductPage.module.scss";

export const GuardianPage = (props) => {
    return (
        <div className={css.product}>
            <h2>Secure Data Vault Storage Solution</h2>
            <p>
                Guardian is a highly secure data vault storage solution, utilizing an array of Guardian servers along with an S3-compatible scalable storage system for elastic storage. Designed with a focus on both security and performance, Guardian ensures quantum-proof military-grade encryption
                for data at rest, making it ideal for sensitive data storage in high-security environments. With fault tolerance, multi-archive redundancy, and high-performance file transfer, Guardian is built to meet the most stringent security and scalability requirements.
            </p>

            <h2>Core Features</h2>
            <ul>
                <li>
                    <strong>Multi-server Fault Tolerant Design</strong>: Guardian provides a fault-tolerant infrastructure through its multi-server architecture, ensuring continuous operation even in the event of server failures.
                </li>
                <li>
                    <strong>Secure File Stream Ingress and Egress</strong>: Designed for secure data transfer, Guardian protects files during ingress and egress with quantum-proof encryption.
                </li>
                <li>
                    <strong>Quantum-Proof Encryption at Rest</strong>: Guardian utilizes 8192-bit military-grade encryption to ensure all data stored is protected against future quantum threats.
                </li>
                <li>
                    <strong>Multi-Archive and Multi-Cloud Replication</strong>: Guardian offers seamless replication across multiple archives and clouds for redundancy and disaster recovery.
                </li>
                <li>
                    <strong>Proprietary TCP-based Ingest Channel</strong>: The system includes a proprietary high-performance TCP-based channel optimized for secure and efficient data ingestion.
                </li>
                <li>
                    <strong>Optimized for High-Performance and Security</strong>: With multi-threading and small-binary architecture, Guardian is built to deliver both high performance and advanced security, catering to the most demanding use cases.
                </li>
                <li>
                    <strong>Flexible Deployment Options</strong>: Guardian can be deployed on bare-metal servers, Docker containers, or hardware appliances, providing flexibility to meet different infrastructure needs.
                </li>
            </ul>
        </div>
    );
};
