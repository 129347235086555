import UXIcon from "../../ux/icon";
import {NavSlider} from "./NavSlider";
import css from "./Navigation.module.scss";
import {products} from "./content";

export const Navigation = (props) => {
    const {mobile} = props;
    const allProps = {
        ...props
    };

    return mobile ? <Mobile {...allProps} /> : <Desktop {...allProps} />;
};

export default Navigation;

const Desktop = (props) => {
    const {pageKey, allPages, onClickTogglePage, bodyClasses} = props;

    const productsList = _.keys(products);
    const showingPage = pageKey !== null;

    return (
        <div className={classnames(css.nav, {[css.show]: showingPage}, bodyClasses(props))}>
            <div className={classnames(css.logo, {[css.show]: showingPage})} onClick={onClickTogglePage(null)}>
                <UXIcon name="arcaneus_logo" />
                Arcaneus
            </div>
            {productsList.map((product) => {
                const selected = pageKey == product;
                const icon = selected ? css.spin : "";
                return (
                    <div key={product} className={classnames({[css.selected]: selected})} onClick={onClickTogglePage(product)}>
                        {product}
                        <span>
                            <UXIcon name="arcaneus_add" classes={icon} />
                        </span>
                    </div>
                );
            })}
            <div className={classnames({[css.selected]: pageKey == "About"})} onClick={onClickTogglePage("About")}>
                About
            </div>
        </div>
    );
};

const Mobile = (props) => {
    const {onClickTogglePage, onClickShowSlider, showSlider, pageKey, bodyClasses} = props;

    const icon = showSlider ? css.spin : "";
    const icon_name = showSlider ? "close" : "menu";
    const showingPage = pageKey !== null;

    return (
        <div className={classnames(css.nav, {[css.show]: showingPage}, bodyClasses(props))}>
            <div className={classnames(css.logo, {[css.show]: showingPage})} onClick={onClickTogglePage(null)}>
                <UXIcon name="arcaneus_logo" />
                Arcaneus
            </div>
            <div className={css.menu}>
                <div className={classnames(css.menu_trigger, {[css.mobile]: props?.mobile})} onClick={onClickShowSlider}>
                    <span>
                        <UXIcon name={icon_name} classes={icon} />
                    </span>
                </div>
                <NavSlider {...props} />
            </div>
        </div>
    );
};
