import change_screen from "assets/icons/v1/icon_df_changescreen.svg";
import media_monitor_on from "assets/icons/v1/player/icon_m_headset.svg";
import media_mic_off from "assets/icons/v1/player/icon_m_mic_off.svg";
import media_mic_on from "assets/icons/v1/player/icon_m_mic_on.svg";
import media_cam_off from "assets/icons/v1/player/icon_m_videocam_off.svg";
import media_cam_on from "assets/icons/v1/player/icon_m_videocam_on.svg";
import media_eye_closed from "assets/icons/v1/player/icon_m_visibility_off.svg";
import media_eye_open from "assets/icons/v1/player/icon_m_visibility_on.svg";
import player_volume_mute from "assets/icons/v1/player/icon_m_volume_off.svg";
import player_volume from "assets/icons/v1/player/icon_m_volume_up.svg";

export const media = {
    media_cam_off,
    media_cam_on,
    media_eye_closed,
    media_eye_open,
    media_mic_off,
    media_mic_on,
    media_vol_on: player_volume,
    media_vol_off: player_volume_mute,
    media_monitor_on,
    change_screen
};
