import reaction_flame from "assets/icons/v1/reactions/reaction_flame.svg";
import reaction_flame_trail from "assets/icons/v1/reactions/reaction_flame_circletrail.svg";
import reaction_heart from "assets/icons/v1/reactions/reaction_heartfill.svg";
import reaction_heart_trail from "assets/icons/v1/reactions/reaction_heartfill_circletrail.svg";
import reaction_wave from "assets/icons/v1/reactions/reaction_wave.svg";
import reaction_wave_trail from "assets/icons/v1/reactions/reaction_wave_circletrail.svg";

export const reactions = {
    reaction_flame,
    reaction_flame_trail,
    reaction_heart,
    reaction_heart_trail,
    reaction_wave,
    reaction_wave_trail
};
