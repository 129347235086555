import social_website from "assets/icons/v1/form/icon_m_link.svg";
// import social_discord from "assets/icons/v1/social/icon_social_discord.svg";
import social_etsy from "assets/icons/v1/social/icon_social_etsy.svg";
import social_facebook from "assets/icons/v1/social/icon_social_facebook.svg";
import social_instagram from "assets/icons/v1/social/icon_social_instagram.svg";
import social_linkedin from "assets/icons/v1/social/icon_social_linkedin.svg";
import social_patreon from "assets/icons/v1/social/icon_social_patreon.svg";
import social_paypal from "assets/icons/v1/social/icon_social_paypal.svg";
import social_snapchat from "assets/icons/v1/social/icon_social_snapchat.svg";
import social_soundcloud from "assets/icons/v1/social/icon_social_soundcloud.svg";
import social_spotify from "assets/icons/v1/social/icon_social_spotify.svg";
// import social_tiktok from "assets/icons/v1/social/icon_social_tiktok.svg";
import social_tumblr from "assets/icons/v1/social/icon_social_tumblr.svg";
import social_twitch from "assets/icons/v1/social/icon_social_twitch.svg";
// import social_twitter from "assets/icons/v1/social/icon_social_twitter.svg";
import social_vimeo from "assets/icons/v1/social/icon_social_vimeo.svg";
import social_youtube from "assets/icons/v1/social/icon_social_youtube.svg";
// v2
import social_discord from "assets/icons/v2/social/icon_df_discord.svg";
import social_twitter from "assets/icons/v2/social/icon_df_xsocial.svg";
import social_kick from "assets/icons/v2/social/icon_social_kick.svg";
import social_rumble from "assets/icons/v2/social/icon_social_rumble.svg";
import social_tiktok from "assets/icons/v2/social/icon_social_tiktok.svg";
import social_trovo from "assets/icons/v2/social/icon_social_trovo.svg";

export const social = {
    social_discord,
    social_etsy,
    social_facebook,
    social_instagram,
    social_linkedin,
    social_patreon,
    social_paypal,
    social_snapchat,
    social_soundcloud,
    social_spotify,
    social_tiktok,
    social_tumblr,
    social_twitch,
    social_twitter,
    social_vimeo,
    social_youtube,
    social_website,

    social_trovo,
    social_rumble,
    social_tiktok,
    social_kick
};
