import discord from "assets/icons/v2/social/icon_df_discord.svg";
import xsocial from "assets/icons/v2/social/icon_df_xsocial.svg";
import kick from "assets/icons/v2/social/icon_social_kick.svg";
import rumble from "assets/icons/v2/social/icon_social_rumble.svg";
import tiktok from "assets/icons/v2/social/icon_social_tiktok.svg";
import trovo from "assets/icons/v2/social/icon_social_trovo.svg";

export const social = {
    discord,
    xsocial,
    kick,
    rumble,
    tiktok,
    trovo
};
