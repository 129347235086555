import player_exit from "assets/icons/v1/icon_m_close.svg";
import emote_bolt from "assets/icons/v1/icon_m_offline_bolt.svg";
import tune from "assets/icons/v1/icon_m_tune.svg";
import player_cc from "assets/icons/v1/player/cc.svg";
import player_enter from "assets/icons/v1/player/icon_m_airplane.svg";
import player_caret_left from "assets/icons/v1/player/icon_m_arrow_left.svg";
import filter from "assets/icons/v1/player/icon_m_filter_list.svg";
import player_fullscreen from "assets/icons/v1/player/icon_m_fullscreen.svg";
import echo from "assets/icons/v1/player/icon_m_noise_aware.svg";
import player_pause from "assets/icons/v1/player/icon_m_pause.svg";
import player_play from "assets/icons/v1/player/icon_m_play_arrow.svg";
import player_cameraonoff from "assets/icons/v1/player/icon_m_power_settings_new.svg";
import player_settings from "assets/icons/v1/player/icon_m_settings.svg";
import player_users from "assets/icons/v1/player/icon_m_supervisor_account.svg";
import player_volume_mute from "assets/icons/v1/player/icon_m_volume_off.svg";
import player_volume from "assets/icons/v1/player/icon_m_volume_up.svg";
import player_miniscreen from "assets/icons/v1/player/miniplayer.svg";
import player_theatrescreen from "assets/icons/v1/player/theatre.svg";
import player_arrow_left from "assets/icons/v1/player/u-arrow-left.svg";

export const player = {
    player_arrow_left,
    player_settings,
    player_enter,
    player_pause,
    player_play,
    player_volume,
    player_volume_mute,
    player_cc,
    player_fullscreen,
    player_miniscreen,
    player_theatrescreen,
    player_cameraonoff,
    player_exit,
    player_users,
    player_caret_left,
    filter,
    echo,
    emote_bolt,
    tune
};
