import chevron_down from "assets/icons/v2/arrows/icon_un_chevron_down.svg";
import chevron_left from "assets/icons/v2/arrows/icon_un_chevron_left.svg";
import chevron_right from "assets/icons/v2/arrows/icon_un_chevron_right.svg";
import chevron_up from "assets/icons/v2/arrows/icon_un_chevron_up.svg";

import arrow_down from "assets/icons/v2/arrows/icon_un_arrow_narrow_down.svg";
import arrow_left from "assets/icons/v2/arrows/icon_un_arrow_narrow_left.svg";
import arrow_right from "assets/icons/v2/arrows/icon_un_arrow_narrow_right.svg";
import arrow_up from "assets/icons/v2/arrows/icon_un_arrow_narrow_up.svg";

import skinny_arrow_left from "assets/icons/v2/arrows/icon_pt_skinnyarrow_left.svg";
import skinny_arrow_right from "assets/icons/v2/arrows/icon_pt_skinnyarrow_right.svg";

import flip_backward from "assets/icons/v2/arrows/icon_un_flip_backward.svg";
import regenerate from "assets/icons/v2/arrows/icon_un_refresh_cw_02.svg";
import connect from "assets/icons/v2/arrows/icon_un_switch_horizontal_01.svg";

import arrow_up_right from "assets/icons/v2/arrows/icon_un_arrow_up_right.svg";

export const arrows = {
    skinny_arrow_right,
    skinny_arrow_left,
    chevron_down,
    chevron_up,
    chevron_right,
    chevron_left,
    arrow_down,
    arrow_left,
    arrow_right,
    arrow_up,
    flip_backward,
    arrow_up_right,
    regenerate,
    connect
};
