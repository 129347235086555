// import video_chat from "assets/icons/v1/icon_mline_chat_bubble.svg";
// import video_group from "assets/icons/v1/icon_mline_group.svg";
import video_group from "assets/icons/v1/icon_m_person.svg";
import video_cam from "assets/icons/v1/icon_mline_videocam.svg";
import video_chat from "assets/icons/v1/overlays/icon_m_bolt.svg";

export const overlay = {
    video_chat,
    video_group,
    video_cam
};
