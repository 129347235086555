import Link from "next/link";
import React from "react";

const UXLink = (props) => {
    const {replace, prefetch, scroll, route, params, getRouteLink, target} = props;

    if (!getRouteLink) throw new Error("You forgot to pass ...props into ArenaLink");
    if (!route) throw new Error("No route prop on ArenaLink");

    const href = getRouteLink(route, params || {});
    if (!href) {
        console.warn(`No href determined from route ${route}`);
        return false;
    }

    if (!href) throw new Error("No route matched for proper href");

    const linkprops = {
        replace: replace || false,
        // prefetch: prefetch === false ? false : true,
        scroll: scroll || true,
        href: href
    };

    if (target) {
        return (
            <a target={target} href={linkprops.href}>
                {props.children}
            </a>
        );
    }

    return <Link {...linkprops}>{props.children}</Link>;
};

export default UXLink;
