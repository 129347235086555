import css from "./ProductPage.module.scss";

export const VelocityPage = (props) => {
    return (
        <div className={css.product}>
            <h2>Client-Server Accelerated File Transfer</h2>
            <p>
                Velocity is an advanced client-server accelerated file transfer solution designed to move data at unprecedented speeds. Leveraging quantum-proof encryption, Velocity ensures secure data transfer both in-flight and at rest. By utilizing two distinct protocols—UDP for ultra-fast
                delivery and TCP for reliable transport across complex networks—Velocity achieves performance gains of up to 200x over traditional methods, making it ideal for high-latency environments and mission-critical file transfers.
            </p>

            <h2>Core Features</h2>
            <ul>
                <li>
                    <strong>Accelerated File Transfer</strong>: Velocity is optimized for rapid file transfers, ensuring faster delivery in high-latency and long-distance connections.
                </li>
                <li>
                    <strong>Reliable UDP and TCP Transport Options</strong>: Velocity offers both UDP and TCP protocols, providing flexibility for different network conditions and performance requirements.
                </li>
                <li>
                    <strong>Checkpoint Recovery</strong>: Transfers are recoverable at the next byte in case of interruptions, ensuring data integrity and seamless recovery.
                </li>
                <li>
                    <strong>Quantum-Proof Encryption</strong>: Data is protected with 1024-bit encryption during transit and 8192-bit encryption at rest, safeguarding against quantum computing threats.
                </li>
                <li>
                    <strong>Up to 200x Performance Gains</strong>: Velocity delivers up to 200x faster performance compared to traditional HTTP(S) and TCP approaches, particularly in high-latency environments.
                </li>
                <li>
                    <strong>Overwrite Protections</strong>: Built-in protections prevent unintended overwrites during file transfers, ensuring data integrity.
                </li>
                <li>
                    <strong>Remote Admin Features</strong>: Administrators can remotely monitor and manage file transfers for enhanced control and efficiency.
                </li>
                <li>
                    <strong>One-Way and Bi-Directional File Sync</strong>: Supports both one-way and bi-directional file synchronization for flexible data management across systems.
                </li>
                <li>
                    <strong>Proven Technology</strong>: Velocity is based on Broadwake’s proven technologies, making it a trusted solution deployed in over 25,000 sites worldwide.
                </li>
            </ul>
        </div>
    );
};
