import home_fill from "assets/icons/fifth/icon_un_home_smile_fill.svg";
import home_line from "assets/icons/fifth/icon_un_home_smile_line.svg";

import find_fill from "assets/icons/fifth/icon_un_search_md_fill.svg";
import find_line from "assets/icons/fifth/icon_un_search_md_line.svg";

import intuitives_fill from "assets/icons/fifth/icon_un_glasses_01_fill.svg";
import intuitives_line from "assets/icons/fifth/icon_undf_glasses_01_line.svg";

import mind_body_fill from "assets/icons/fifth/icon_m_spa_fill.svg";
import mind_body_line from "assets/icons/fifth/icon_m_spa_line.svg";

import wellness_fill from "assets/icons/fifth/icon_un_activity_heart_fill.svg";
import wellness_line from "assets/icons/fifth/icon_un_activity_heart_line.svg";

import activities_fill from "assets/icons/fifth/icon_un_calendar_fill.svg";
import activities_line from "assets/icons/fifth/icon_un_calendar_line.svg";

import library_fill from "assets/icons/fifth/icon_un_book_open_01_fill.svg";
import library_line from "assets/icons/fifth/icon_un_book_open_01_line.svg";

import store_fill from "assets/icons/fifth/icon_un_shopping_bag_03_fill.svg";
import store_line from "assets/icons/fifth/icon_un_shopping_bag_03_line.svg";

export const fifth = {
    home_line,
    home_fill,
    find_fill,
    find_line,
    intuitives_fill,
    intuitives_line,
    mind_body_fill,
    mind_body_line,
    wellness_fill,
    wellness_line,
    activities_fill,
    activities_line,

    library_line,
    library_fill,
    store_line,
    store_fill
};
