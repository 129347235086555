import type_default from "assets/icons/v1/settings/icon_df_text_default.svg";
import expand_less from "assets/icons/v1/settings/icon_m_expand_less.svg";
import type_decrease from "assets/icons/v1/settings/icon_m_text_decrease.svg";
import type_increase from "assets/icons/v1/settings/icon_m_text_increase.svg";
import themethumb_astro from "assets/icons/v1/settings/themethumb_astro.svg";
import themethumb_frosty from "assets/icons/v1/settings/themethumb_frosty.svg";
import themethumb_raven from "assets/icons/v1/settings/themethumb_raven.svg";

export const settings = {
    type_decrease,
    type_default,
    type_increase,
    themethumb_astro,
    themethumb_frosty,
    themethumb_raven,
    expand_less
};
