import bookmark from "assets/icons/v2/general/icon_un_bookmark.svg";
import bookmark_check from "assets/icons/v2/general/icon_un_bookmark_check_fill.svg";
import check_circle from "assets/icons/v2/general/icon_un_check_circle.svg";
import check_circle_fill from "assets/icons/v2/general/icon_un_check_circle_1.svg";
import check_square_fill from "assets/icons/v2/general/icon_un_check_square.svg";
import copy from "assets/icons/v2/general/icon_un_copy_03.svg";
import edit from "assets/icons/v2/general/icon_un_edit_03.svg";
import eye_fill from "assets/icons/v2/general/icon_un_eye.svg";
import preview from "assets/icons/v2/general/icon_un_eye_fill.svg";
import eye_off_fill from "assets/icons/v2/general/icon_un_eye_off.svg";
import apps_fill from "assets/icons/v2/general/icon_un_grid_01_fill.svg";
import apps_line from "assets/icons/v2/general/icon_un_grid_01_line.svg";
import donate from "assets/icons/v2/general/icon_un_heart_hexagon_fill.svg";
import support from "assets/icons/v2/general/icon_un_help_circle.svg";
import linkto from "assets/icons/v2/general/icon_un_link_01.svg";
import log_in from "assets/icons/v2/general/icon_un_log_in_04.svg";
import log_out from "assets/icons/v2/general/icon_un_log_out_04.svg";
import menu from "assets/icons/v2/general/icon_un_menu_02.svg";
import add from "assets/icons/v2/general/icon_un_plus.svg";
import search from "assets/icons/v2/general/icon_un_search_md.svg";
import ban from "assets/icons/v2/general/icon_un_slash_octagon.svg";
import speedometer from "assets/icons/v2/general/icon_un_speedometer_03.svg";
import x_circle from "assets/icons/v2/general/icon_un_x_circle_fill.svg";
import x_close from "assets/icons/v2/general/icon_un_x_close.svg";

export const general = {
    linkto,
    x_close,
    log_out,
    log_in,
    support,
    check_circle_fill,
    check_circle,
    check_square_fill,
    eye_fill,
    eye_off_fill,
    x_circle,
    menu,
    donate,
    search,
    ban,
    bookmark,
    bookmark_check,
    add,
    copy,
    edit,
    preview,
    apps_line,
    apps_fill,
    speedometer
};
