import alarm_clock_plus from "assets/icons/v2/time/icon_un_alarm_clock_plus.svg";
import calendar from "assets/icons/v2/time/icon_un_calendar.svg";
import calendar_check from "assets/icons/v2/time/icon_un_calendar_check_01.svg";
import calendar_plus from "assets/icons/v2/time/icon_un_calendar_plus_01.svg";

export const time = {
    calendar_plus,
    alarm_clock_plus,
    calendar,
    calendar_check
};
