import css from "./ProductPage.module.scss";

export const JavelinPage = (props) => {
    return (
        <div className={css.product}>
            <h2>Real-Time Secure Packet Delivery</h2>
            <p>
                Javelin is a cutting-edge solution engineered for real-time, low-latency packet delivery in security-sensitive environments. Leveraging Arcaneus's quantum-proof military-grade encryption, Javelin ensures uncompromised data integrity and resilience, even in the most challenging
                network conditions. Built to handle mission-critical applications, it delivers unparalleled performance across video, audio, and data streams, making it ideal for industries requiring high-performance, encrypted packet delivery with minimal latency.
            </p>

            <h2>Core Features</h2>
            <ul>
                <li>
                    <strong>UDP-based Resilience</strong>: Capable of recovering from up to 20% packet loss, Javelin’s proprietary recovery technology minimizes latency impact, ensuring uninterrupted data flow.
                </li>
                <li>
                    <strong>Multiplexing & Demultiplexing</strong>: Supports many-to-one multiplexing of up to 256 channels and one-to-many demultiplexing, allowing flexible real-time data transport scenarios.
                </li>
                <li>
                    <strong>Quantum-Proof Encryption</strong>: Data is encrypted in flight, securing sensitive information during transmission, making it a robust choice for high-security environments.
                </li>
                <li>
                    <strong>Versatile Deployment</strong>: A single binary design ensures seamless deployment across bare-metal servers, Docker containers, or hardware appliances, offering flexibility in various infrastructures.
                </li>
                <li>
                    <strong>Pass-through Relay Functionality</strong>: Javelin’s pass-through relay enables efficient stream routing between multiple servers, optimizing the network for high-speed, low-latency delivery.
                </li>
                <li>
                    <strong>Real-Time Low Latency</strong>: Specifically optimized for video, audio, and IoT data distribution, Javelin is designed to maintain millisecond latencies, making it perfect for live and interactive applications.
                </li>
                <li>
                    <strong>High-Performance Delivery</strong>: Engineered for demanding real-time applications, Javelin delivers superior performance, ensuring consistent and reliable packet delivery with minimal overhead.
                </li>
            </ul>
        </div>
    );
};
