import design from "assets/icons/v1/icon_m_palette.svg";
import streams from "assets/icons/v2/media/icon_un_signal_03.svg";
import info from "assets/icons/v2/time/icon_un_alarm_clock_plus.svg";
import events from "assets/icons/v2/time/icon_un_calendar.svg";
import profile from "assets/icons/v2/user/icon_un_user_03.svg";

export const nav_admin = {
    events,
    profile,
    streams,
    design,
    info
};
