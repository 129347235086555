import lock_closed from "assets/icons/v2/security/icon_un_lock_03_fill.svg";
import lock_closed_alt from "assets/icons/v2/security/icon_un_lock_04_fill.svg";
import lock_open from "assets/icons/v2/security/icon_un_lock_unlocked_03_fill.svg";
import lock_open_alt from "assets/icons/v2/security/icon_un_lock_unlocked_04_fill.svg";

export const security = {
    lock_closed,
    lock_closed_alt,
    lock_open,
    lock_open_alt
};
