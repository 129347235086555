import iconset from "components/icons/icons";
import React from "react";
import css from "./icon.module.scss";
import UXLink from "./link";
const UXIcon = React.forwardRef((props, ref) => {
    const {route, classes, onClick, getRouteLink, fallback} = props;

    let name = props?.name;

    if (!name) {
        throw new Error(`Missing prop name on Icon`);
    }

    let icon = iconset[name];

    if (!icon && fallback) {
        name = fallback;
        icon = iconset[name];
    }

    if (!icon) {
        throw new Error(`No icon of name ${name}`);
    }

    const className = classnames(css.icon, "icon", `icon-${name}`, classes);

    if (route && getRouteLink) {
        return (
            <UXLink {...props}>
                <span className={className} dangerouslySetInnerHTML={{__html: icon}} />
            </UXLink>
        );
    }
    return <span ref={ref} onClick={onClick} className={className} dangerouslySetInnerHTML={{__html: icon}} />;
});

export default UXIcon;
