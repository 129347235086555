import google_g from "assets/icons/v2/button/icon_df_googleg_color.svg";
import add_reaction from "assets/icons/v2/button/icon_m_add_reaction.svg";

import play from "assets/icons/v2/button/icon_un_play.svg";
import share from "assets/icons/v2/button/icon_un_share_07.svg";
import ticket from "assets/icons/v2/button/icon_un_ticket_01.svg";

export const button = {
    play,
    share,
    add_reaction,
    ticket,

    google_g
};
